.btn {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: medium;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(37, 108, 225, 0.8);
  }

  .right-icon {
    display: flex;
    margin-left: 10px;
  }
}
