.home-container {
  position: relative;
  top: 80px;
  background: whitesmoke;
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;

  .card {
    display: flex;
    height: 80%;
    width: 80%;
    border-radius: 15px;
    background: white;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

    .image-container {
      flex: 1;

      .image {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        border-radius: 15px 0 0 15px;
      }
    }

    .welcome-msg-container {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .welcome-msg {
        width: 80%;
        text-align: center;
      }

      .btn {
        margin-top: 50px;
      }
    }
  }
}
