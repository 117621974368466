.navbar {
  position: fixed;
  width: 100%;
  background: #000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;

  .link {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
      color: #15cdfc;
    }

    .logo {
      max-height: 100%;
    }
  }

  .btn {
    &:hover {
      background: #fff;
      color: #010606;
    }
  }

  .bars-icon {
    display: none;
    color: #fff;
    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 75%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .nav-menu {
    display: flex;
    align-items: center;

    &.mr {
      margin-right: 24px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
