@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
*{box-sizing:border-box;margin:0;padding:0;font-family:"Lato", sans-serif}

.loader-container{position:fixed;z-index:99;background:rgba(0,0,0,0.35);height:100%;width:100%;display:flex;justify-content:center;align-items:center}

.navbar{position:fixed;width:100%;background:#000;height:80px;display:flex;justify-content:space-between;padding:0.5rem calc((100vw - 1000px) / 2);z-index:10}.navbar .link{color:#fff;display:flex;align-items:center;text-decoration:none;padding:0 1rem;height:100%;cursor:pointer}.navbar .link.active{color:#15cdfc}.navbar .link .logo{max-height:100%}.navbar .btn:hover{background:#fff;color:#010606}.navbar .bars-icon{display:none;color:#fff}@media screen and (max-width: 768px){.navbar .bars-icon{display:block;position:absolute;top:0;right:0;transform:translate(-100%, 75%);font-size:1.8rem;cursor:pointer}}.navbar .nav-menu{display:flex;align-items:center}.navbar .nav-menu.mr{margin-right:24px}@media screen and (max-width: 768px){.navbar .nav-menu{display:none}}

.btn{display:flex;align-items:center;border-radius:4px;background:#256ce1;padding:10px 22px;color:#fff;outline:none;border:none;cursor:pointer;transition:all 0.2s ease-in-out;text-decoration:none;font-size:medium}.btn:hover{transition:all 0.2s ease-in-out;background:rgba(37,108,225,0.8)}.btn .right-icon{display:flex;margin-left:10px}

.has-error{border:1px solid red !important}.addon-input-container{display:flex}

.home-container{position:relative;top:80px;background:whitesmoke;display:flex;height:90vh;justify-content:center;align-items:center}.home-container .card{display:flex;height:80%;width:80%;border-radius:15px;background:white;box-shadow:0px 3px 15px rgba(0,0,0,0.2)}.home-container .card .image-container{flex:1 1}.home-container .card .image-container .image{height:100%;width:100%;max-height:100%;max-width:100%;border-radius:15px 0 0 15px}.home-container .card .welcome-msg-container{flex:1 1;flex-direction:column;display:flex;justify-content:center;align-items:center}.home-container .card .welcome-msg-container .welcome-msg{width:80%;text-align:center}.home-container .card .welcome-msg-container .btn{margin-top:50px}

.provide-data-container{position:relative;top:100px;height:100%}.provide-data-container .title{text-align:center}.provide-data-container .form-container{display:flex;justify-content:center;margin-top:30px;margin-bottom:30px}

.not-found-container{position:relative;top:80px;height:90vh;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}

.login-container{position:relative;top:80px;height:90.5vh}.login-container .rs-row{height:100%}.login-container .rs-row .image-container{height:100%;display:flex;align-content:center;justify-content:center}.login-container .rs-row .image-container img{width:60%}.login-container .rs-row .form-container{height:100%;display:flex;flex-direction:column;justify-content:center;background:#256ce1;color:#fff}.login-container .rs-row .form-container .form-container{display:flex;justify-content:center;align-items:center}.login-container .rs-row .form-container .form-container .title{text-align:center;margin-bottom:30px}.login-container .rs-row .form-container .form-container .rs-form-group .rs-btn{background:#fff;color:#000}.login-container .rs-row .form-container .form-container .rs-btn{background:#000}

.signup-container{position:relative;top:80px;height:90.5vh}.signup-container .rs-row{height:100%}.signup-container .rs-row .image-container{height:100%;display:flex;align-content:center;justify-content:center}.signup-container .rs-row .image-container img{width:60%}.signup-container .rs-row .form-container{height:100%;display:flex;flex-direction:column;justify-content:center;background:#256ce1;color:#fff}.signup-container .rs-row .form-container .form-container{display:flex;justify-content:center;align-items:center}.signup-container .rs-row .form-container .form-container .title{text-align:center;margin-bottom:30px}.signup-container .rs-row .form-container .form-container .rs-form-group .rs-btn{background:#fff;color:#000}.signup-container .rs-row .form-container .form-container .rs-btn{background:#000}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

