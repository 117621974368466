.signup-container {
  position: relative;
  top: 80px;
  height: 90.5vh;

  .rs-row {
    height: 100%;

    .image-container {
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 60%;
      }
    }

    .form-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #256ce1;
      color: #fff;

      .form-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
          text-align: center;
          margin-bottom: 30px;
        }

        .rs-form-group {
          .rs-btn {
            background: #fff;
            color: #000;
          }
        }

        .rs-btn {
          background: #000;
        }
      }
    }
  }
}
